body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Header_sticky__1Lf0C {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #1a1a1a;
  opacity: 0.9;
  width: 100%;
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .Header_header__2Hz6k {
    padding: 0 0;
  }
}

/* ----------- iPhone 6, 6S, 7, 8 and X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 827px) and (-webkit-min-device-pixel-ratio: 2) {
  .Header_header__2Hz6k {
    padding: 0 0;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 3000px) {
  .Header_header__2Hz6k {
    top: 0;
    width: 100%;
    padding: 2.5em 0;
  }
}

.NavbarWrapper_navbar-brand__wexOA {
  color: #fff;
  font-size: 21px;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 29px;
  text-align: center;
}

.NavbarWrapper_navlink__1lDMD {
  color: #fff;
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: center;
  text-decoration: none;
  padding: 0.5em;
}

nav {
  padding: 0 !important;
}

a {
  color: #ffffff !important;
  font-family: Avenir, sans-serif !important;
}

a:hover {
  opacity: 0.5;
  color: #fff;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  text-decoration: none !important;
}

button {
  border-color: transparent !important;
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .NavbarWrapper_navbar-brand__wexOA {
    font-size: 0.9rem !important;
    letter-spacing: 2px !important;
    margin-right: 0rem !important;
  }

  .NavbarWrapper_navlink__1lDMD {
    font-size: 18px;
    line-height: 25px;
  }

  .NavbarWrapper_logo__2uoe8 {
    width: 8em;
    margin-right: 1em;
    margin-bottom: 0.25em;
  }
}

/* ----------- iPhone 6, 6S, 7, 8 and X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 827px) and (-webkit-min-device-pixel-ratio: 2) {
  .NavbarWrapper_navbar-brand__wexOA {
    font-size: 0.9rem !important;
  }

  .NavbarWrapper_navlink__1lDMD {
    font-size: 18px;
    line-height: 25px;
  }

  .NavbarWrapper_logo__2uoe8 {
    width: 10em;
    margin-right: 1em;
    margin-bottom: 0.25em;
    margin-top: 2em;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 3000px) {
  .NavbarWrapper_navbar-brand__wexOA {
    font-size: 21px;
    letter-spacing: 2px;
  }

  .NavbarWrapper_navlink__1lDMD {
    font-size: 18px;
    line-height: 25px;
  }

  .NavbarWrapper_logo__2uoe8 {
    width: 13em;
    margin-right: 1em;
    margin-bottom: 0.5em;
  }
}

.Footer_footer__3sO0w {
  background-color: #020202;
}

.Footer_container__20F8a {
  padding: 2em 2em;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
}

.Footer_copyright__dlFiP {
  color: #ffffff;
  font-family: Avenir, sans-serif;
  font-size: 1em;
  font-weight: 300;
}

.Footer_social__container__1Du92 {
  display: -webkit-flex;
  display: flex;
  padding: 6em 8em;
  -webkit-justify-content: center;
          justify-content: center;
}

.Footer_social__link__p1Z65 {
  width: 100px;
  margin: 1em 4em;
}

.Footer_footer__title__QX9-k {
  font-size: 2em;
  font-weight: 900;
  color: #fff;
}

.Footer_footer__caption__1Z20l {
  text-align: center;
  font-family: "Roboto", "sans-serif";
  font-weight: 300;
}

.Card_card__2NK9j {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  max-width: 800px;
  border-radius: 0.5em;
  background-color: #ffffff;
  font-weight: 500;
  font-size: 1em;
  position: relative;
  margin: 2em auto;
}

.Card_full__3jSyM {
  width: 100%;
}

.Card_card__2NK9j:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .Card_card__2NK9j {
    margin: 2em auto;
    padding: 0.5em 1.5em;
  }
}

/* ----------- iPhone 6, 6S, 7, 8 and X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 8127px) and (-webkit-min-device-pixel-ratio: 2) {
  .Card_card__2NK9j {
    margin: 2em auto;
    padding: 1em 2em;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 2), screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .Card_card__2NK9j {
    margin: 2em auto;
    padding: 2em 3em;
  }
}

.SectionTitle_section-title__FzPiB {
  color: #ffffff;
  font-family: "Raleway", "sans-serif";
  font-weight: 900;
  position: relative;
}

.SectionTitle_section-title--hero__2YLTQ {
  font-family: "Raleway", "sans-serif";
}

.SectionTitle_section__title--over-image__2GIXu {
  font-family: "Raleway", "sans-serif";
}

.SectionTitle_section-title--light__1tzm4 {
  color: #ffffff;
}

.SectionTitle_section-title--dark__27OKe {
  color: #333333;
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .SectionTitle_section-title__FzPiB {
    letter-spacing: -0.96px;
    line-height: 1.2em;
    font-size: 3em;
    margin-bottom: 0.25em;
  }

  .SectionTitle_section-title--hero__2YLTQ {
    font-size: 1.8em;
    margin-top: 6.25em;
  }
}

/* ----------- iPhone 6, 6S, 7, 8 and X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 827px) and (-webkit-min-device-pixel-ratio: 2) {
  .SectionTitle_section-title__FzPiB {
    letter-spacing: -0.96px;
    line-height: 1.2em;
    font-size: 3.4em;
    margin-bottom: 0.25em;
  }

  .SectionTitle_section-title--hero__2YLTQ {
    font-size: 2em;
    margin-top: 7em;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 3000px) {
  .SectionTitle_section-title__FzPiB {
    letter-spacing: -0.96px;
    font-size: 5.375em;
    font-weight: 900;
    text-align: left;
    margin-bottom: 0.25em;
  }

  .SectionTitle_section-title--hero__2YLTQ {
    margin-top: 1em;
    font-weight: 900;
    font-size: 4.5em;
  }
}

.SectionSubtitle_section__subtitle__PgW7N {
  color: #ffffff;
  font-family: "Roboto", "sans-serif";
  font-size: 1.5em;
  font-weight: 300;
  font-style: oblique;
  letter-spacing: -0.3px;
  position: relative;
}

.SectionSubtitle_section__subtitle--red__157TP {
  color: #b72f19;
}

.SectionSubtitle_section__subtitle--hero__3tdxD {
  margin-top: 2em;
  font-style: normal;
}

.SectionSubtitle_section__subtitle--light__26Kmo {
  color: #ffffff;
}

.SectionSubtitle_section__subtitle--dark__1q4dH {
  color: #1a1a1a;
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .SectionSubtitle_section__subtitle__PgW7N {
    margin-bottom: 1em;
    max-width: 45rem;
    font-size: 1.25em;
    line-height: 1.25em;
  }
}

/* ----------- iPhone 6, 6S, 7, 8 and X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 827px) and (-webkit-min-device-pixel-ratio: 2) {
  .SectionSubtitle_section__subtitle__PgW7N {
    margin-bottom: 1em;
    max-width: 45rem;
    font-size: 1.5em;
    line-height: 1.25em;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 3000px) {
  .SectionSubtitle_section__subtitle__PgW7N {
    margin-bottom: 1em;
    max-width: 45rem;
  }
}

.SectionText_section__text__1uJoi {
  color: #ffffff;
  font-family: "Roboto", "sans-serif";
  font-size: 1.375em;
  font-weight: 400;
  line-height: 1.5em;
  position: relative;
}

.SectionText_section__text--light__-DcUH {
  color: #ffffff;
}

.SectionText_section__text--dark__2e5wx {
  color: #333333;
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .SectionText_section__text__1uJoi {
    font-size: 1.25em;
    font-weight: 300;
    line-height: 1.265em;
  }

  .SectionText_section__text--over-image__1k63n {
    font-weight: 900;
    padding-top: 2rem;
  }
}

/* ----------- iPhone 6, 6S, 7, 8 and X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 827px) and (-webkit-min-device-pixel-ratio: 2) {
  .SectionText_section__text__1uJoi {
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1.5em;
  }

  .SectionText_section__text--over-image__1k63n {
    font-weight: 900;
    padding-top: 2rem;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 3000px) {
  .SectionText_section__text__1uJoi {
  }

  .SectionText_section__text--two-columns__3mkYx {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */ /* Firefox */
    column-count: 2;
    -webkit-column-gap: 2em; /* Chrome, Safari, Opera */
    -moz-column-gap: 2em; /* Firefox */
    grid-column-gap: 2em;
    column-gap: 2em;
  }

  .SectionText_section__text--over-image__1k63n {
    font-weight: 900;
    font-size: 2em;
    padding-top: 2rem;
    line-height: 1.2em;
  }
}

.Section_section__24o63 {
  width: 100vw;
  background: no-repeat center;
  background-size: cover;
  position: relative;
  font-family: Avenir, sans-serif;
  background-color: #fffeff;
}

.Section_section--page-header__1Ouyw {
  /*height: 100vh;*/
  background: #625bff;
  background-image: linear-gradient(90deg, #625bff 0%, #88f3ff 100%);
  background-image: -ms-linear-gradient(90deg, #625bff 0%, #88f3ff 100%);
  padding: 1em 6.75em;
}

.Section_section--dark__1gCTI {
  background-color: #020202;
}

.Section_section--light__3_c3j {
  background-color: #f9f9f9;
}

.Section_section--hero__1TrZg {
  background: #625bff;
  background-image: linear-gradient(90deg, #625bff 0%, #88f3ff 100%);
  background-image: -ms-linear-gradient(90deg, #625bff 0%, #88f3ff 100%);
}

.Section_section--bg-image-overlay__6Nx4H {
  position: absolute;
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.40168594624954845) 0%,
    rgba(0, 0, 0, 0.5519749635905888) 49%,
    rgba(0, 0, 0, 0.9074662930703576) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  opacity: 0.7;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .Section_section__24o63 {
    padding: 2em 1.75em;
  }

  .Section_section--hero__1TrZg {
    min-height: 560px;
    padding: 2em 1.75em;
  }
}

/* ----------- iPhone 6, 6S, 7, 8 and X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 827px) and (-webkit-min-device-pixel-ratio: 2) {
  .Section_section__24o63 {
    padding: 2em 2.75em;
  }

  .Section_section--hero__1TrZg {
    min-height: 660px;
    padding: 2em 2.75em;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 3000px) {
  .Section_section__24o63 {
    padding: 9em 6.75em;
  }

  .Section_section--hero__1TrZg {
    min-height: 700px;
    padding: 6em 6.75em;
  }

  .Section_section--narrow__ZJNY3 {
    padding: 9em 12em;
  }

  .Section_section--page-header__1Ouyw {
    padding: 0em 6.75em;
  }
}

.Pages_content__2XdWj {
  background-color: #f9f9f9;
  font-family: Avenir sans-serif;
}

.Pages_blog__XpOMV {
  background-color: #f9f9f9;
  padding: 2em 10em;
}

.Pages_me__1Oc24 {
  width: 100%;
  height: 70vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.Pages_post__link__3t0q-,
.Pages_post__link__3t0q- > a {
  color: #625bff !important;
  font-size: 1.25em;
  padding: 1em 0 !important;
}

.Pages_post__img__1FwRi {
  width: 100%;
  position: relative;
  padding-top: 0.5em;
}

.Pages_post__prev-title__JxuV- {
  color: #424242;
  font-size: 1.5em;
  font-weight: 500;
  font-family: "Raleway", "sans-serif";
  padding: 1em 0;
}

.Pages_post__prev-txt__2Jcbl {
  font-size: 1.25em;
  font-family: "Roboto", "sans-serif";
  font-weight: 500;
  margin-bottom: 1em;
}

.Pages_post__title__2mS2G {
  color: #020202;
  font-size: 2.5em;
  font-weight: 700;
  font-family: "Raleway", "sans-serif";
}

.Pages_section__AaWnk {
  padding: 6em 4em;
  max-width: 1500px;
}

.Pages_post__meta__bGHHR {
  padding: 1em 0;
}

.Pages_post__meta--first-child__2DMjC {
  padding-left: 0;
}

.Pages_post__meta-item__11g8R {
  padding: 0 0.5em;
  color: #333333;
  font-weight: 500;
  font-family: "Roboto", "sans-serif";
}

