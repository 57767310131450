.section-title {
  color: #ffffff;
  font-family: "Raleway", "sans-serif";
  font-weight: 900;
  position: relative;
}

.section-title--hero {
  font-family: "Raleway", "sans-serif";
}

.section__title--over-image {
  font-family: "Raleway", "sans-serif";
}

.section-title--light {
  color: #ffffff;
}

.section-title--dark {
  color: #333333;
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .section-title {
    letter-spacing: -0.96px;
    line-height: 1.2em;
    font-size: 3em;
    margin-bottom: 0.25em;
  }

  .section-title--hero {
    font-size: 1.8em;
    margin-top: 6.25em;
  }
}

/* ----------- iPhone 6, 6S, 7, 8 and X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 827px) and (-webkit-min-device-pixel-ratio: 2) {
  .section-title {
    letter-spacing: -0.96px;
    line-height: 1.2em;
    font-size: 3.4em;
    margin-bottom: 0.25em;
  }

  .section-title--hero {
    font-size: 2em;
    margin-top: 7em;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 3000px) {
  .section-title {
    letter-spacing: -0.96px;
    font-size: 5.375em;
    font-weight: 900;
    text-align: left;
    margin-bottom: 0.25em;
  }

  .section-title--hero {
    margin-top: 1em;
    font-weight: 900;
    font-size: 4.5em;
  }
}
