.navbar-brand {
  color: #fff;
  font-size: 21px;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 29px;
  text-align: center;
}

.navlink {
  color: #fff;
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: center;
  text-decoration: none;
  padding: 0.5em;
}

nav {
  padding: 0 !important;
}

a {
  color: #ffffff !important;
  font-family: Avenir, sans-serif !important;
}

a:hover {
  opacity: 0.5;
  color: #fff;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  text-decoration: none !important;
}

button {
  border-color: transparent !important;
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .navbar-brand {
    font-size: 0.9rem !important;
    letter-spacing: 2px !important;
    margin-right: 0rem !important;
  }

  .navlink {
    font-size: 18px;
    line-height: 25px;
  }

  .logo {
    width: 8em;
    margin-right: 1em;
    margin-bottom: 0.25em;
  }
}

/* ----------- iPhone 6, 6S, 7, 8 and X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 827px) and (-webkit-min-device-pixel-ratio: 2) {
  .navbar-brand {
    font-size: 0.9rem !important;
  }

  .navlink {
    font-size: 18px;
    line-height: 25px;
  }

  .logo {
    width: 10em;
    margin-right: 1em;
    margin-bottom: 0.25em;
    margin-top: 2em;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 3000px) {
  .navbar-brand {
    font-size: 21px;
    letter-spacing: 2px;
  }

  .navlink {
    font-size: 18px;
    line-height: 25px;
  }

  .logo {
    width: 13em;
    margin-right: 1em;
    margin-bottom: 0.5em;
  }
}
