.content {
  background-color: #f9f9f9;
  font-family: Avenir sans-serif;
}

.blog {
  background-color: #f9f9f9;
  padding: 2em 10em;
}

.me {
  width: 100%;
  height: 70vh;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}

.post__link,
.post__link > a {
  color: #625bff !important;
  font-size: 1.25em;
  padding: 1em 0 !important;
}

.post__img {
  width: 100%;
  position: relative;
  padding-top: 0.5em;
}

.post__prev-title {
  color: #424242;
  font-size: 1.5em;
  font-weight: 500;
  font-family: "Raleway", "sans-serif";
  padding: 1em 0;
}

.post__prev-txt {
  font-size: 1.25em;
  font-family: "Roboto", "sans-serif";
  font-weight: 500;
  margin-bottom: 1em;
}

.post__title {
  color: #020202;
  font-size: 2.5em;
  font-weight: 700;
  font-family: "Raleway", "sans-serif";
}

.section {
  padding: 6em 4em;
  max-width: 1500px;
}

.post__meta {
  padding: 1em 0;
}

.post__meta--first-child {
  padding-left: 0;
}

.post__meta-item {
  padding: 0 0.5em;
  color: #333333;
  font-weight: 500;
  font-family: "Roboto", "sans-serif";
}
