.section {
  width: 100vw;
  background: no-repeat center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
  font-family: Avenir, sans-serif;
  background-color: #fffeff;
}

.section--page-header {
  /*height: 100vh;*/
  background: #625bff;
  background-image: linear-gradient(90deg, #625bff 0%, #88f3ff 100%);
  background-image: -moz-linear-gradient(90deg, #625bff 0%, #88f3ff 100%);
  background-image: -webkit-linear-gradient(90deg, #625bff 0%, #88f3ff 100%);
  background-image: -ms-linear-gradient(90deg, #625bff 0%, #88f3ff 100%);
  padding: 1em 6.75em;
}

.section--dark {
  background-color: #020202;
}

.section--light {
  background-color: #f9f9f9;
}

.section--hero {
  background: #625bff;
  background-image: linear-gradient(90deg, #625bff 0%, #88f3ff 100%);
  background-image: -moz-linear-gradient(90deg, #625bff 0%, #88f3ff 100%);
  background-image: -webkit-linear-gradient(90deg, #625bff 0%, #88f3ff 100%);
  background-image: -ms-linear-gradient(90deg, #625bff 0%, #88f3ff 100%);
}

.section--bg-image-overlay {
  position: absolute;
  background: rgb(0, 0, 0);
  background: -moz-radial-gradient(
    circle,
    rgba(0, 0, 0, 0.40168594624954845) 0%,
    rgba(0, 0, 0, 0.5519749635905888) 49%,
    rgba(0, 0, 0, 0.9074662930703576) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(0, 0, 0, 0.40168594624954845) 0%,
    rgba(0, 0, 0, 0.5519749635905888) 49%,
    rgba(0, 0, 0, 0.9074662930703576) 100%
  );
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.40168594624954845) 0%,
    rgba(0, 0, 0, 0.5519749635905888) 49%,
    rgba(0, 0, 0, 0.9074662930703576) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  opacity: 0.7;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .section {
    padding: 2em 1.75em;
  }

  .section--hero {
    min-height: 560px;
    padding: 2em 1.75em;
  }
}

/* ----------- iPhone 6, 6S, 7, 8 and X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 827px) and (-webkit-min-device-pixel-ratio: 2) {
  .section {
    padding: 2em 2.75em;
  }

  .section--hero {
    min-height: 660px;
    padding: 2em 2.75em;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 3000px) {
  .section {
    padding: 9em 6.75em;
  }

  .section--hero {
    min-height: 700px;
    padding: 6em 6.75em;
  }

  .section--narrow {
    padding: 9em 12em;
  }

  .section--page-header {
    padding: 0em 6.75em;
  }
}
