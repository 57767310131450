.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  max-width: 800px;
  border-radius: 0.5em;
  background-color: #ffffff;
  font-weight: 500;
  font-size: 1em;
  position: relative;
  margin: 2em auto;
}

.full {
  width: 100%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .card {
    margin: 2em auto;
    padding: 0.5em 1.5em;
  }
}

/* ----------- iPhone 6, 6S, 7, 8 and X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 8127px) and (-webkit-min-device-pixel-ratio: 2) {
  .card {
    margin: 2em auto;
    padding: 1em 2em;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .card {
    margin: 2em auto;
    padding: 2em 3em;
  }
}
