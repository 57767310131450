.sticky {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #1a1a1a;
  opacity: 0.9;
  width: 100%;
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .header {
    padding: 0 0;
  }
}

/* ----------- iPhone 6, 6S, 7, 8 and X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 827px) and (-webkit-min-device-pixel-ratio: 2) {
  .header {
    padding: 0 0;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 3000px) {
  .header {
    top: 0;
    width: 100%;
    padding: 2.5em 0;
  }
}
