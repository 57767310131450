.section__subtitle {
  color: #ffffff;
  font-family: "Roboto", "sans-serif";
  font-size: 1.5em;
  font-weight: 300;
  font-style: oblique;
  letter-spacing: -0.3px;
  position: relative;
}

.section__subtitle--red {
  color: #b72f19;
}

.section__subtitle--hero {
  margin-top: 2em;
  font-style: normal;
}

.section__subtitle--light {
  color: #ffffff;
}

.section__subtitle--dark {
  color: #1a1a1a;
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .section__subtitle {
    margin-bottom: 1em;
    max-width: 45rem;
    font-size: 1.25em;
    line-height: 1.25em;
  }
}

/* ----------- iPhone 6, 6S, 7, 8 and X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 827px) and (-webkit-min-device-pixel-ratio: 2) {
  .section__subtitle {
    margin-bottom: 1em;
    max-width: 45rem;
    font-size: 1.5em;
    line-height: 1.25em;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 3000px) {
  .section__subtitle {
    margin-bottom: 1em;
    max-width: 45rem;
  }
}
