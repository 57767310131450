.footer {
  background-color: #020202;
}

.container {
  padding: 2em 2em;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
}

.copyright {
  color: #ffffff;
  font-family: Avenir, sans-serif;
  font-size: 1em;
  font-weight: 300;
}

.social__container {
  display: flex;
  padding: 6em 8em;
  justify-content: center;
}

.social__link {
  width: 100px;
  margin: 1em 4em;
}

.footer__title {
  font-size: 2em;
  font-weight: 900;
  color: #fff;
}

.footer__caption {
  text-align: center;
  font-family: "Roboto", "sans-serif";
  font-weight: 300;
}
