.section__text {
  color: #ffffff;
  font-family: "Roboto", "sans-serif";
  font-size: 1.375em;
  font-weight: 400;
  line-height: 1.5em;
  position: relative;
}

.section__text--light {
  color: #ffffff;
}

.section__text--dark {
  color: #333333;
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .section__text {
    font-size: 1.25em;
    font-weight: 300;
    line-height: 1.265em;
  }

  .section__text--over-image {
    font-weight: 900;
    padding-top: 2rem;
  }
}

/* ----------- iPhone 6, 6S, 7, 8 and X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 827px) and (-webkit-min-device-pixel-ratio: 2) {
  .section__text {
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1.5em;
  }

  .section__text--over-image {
    font-weight: 900;
    padding-top: 2rem;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 3000px) {
  .section__text {
  }

  .section__text--two-columns {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
    -webkit-column-gap: 2em; /* Chrome, Safari, Opera */
    -moz-column-gap: 2em; /* Firefox */
    column-gap: 2em;
  }

  .section__text--over-image {
    font-weight: 900;
    font-size: 2em;
    padding-top: 2rem;
    line-height: 1.2em;
  }
}
